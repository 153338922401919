export const API_BASE_URL = (import.meta.env.VITE_API_BASE_URL as string) || '/api';

/** A string describing the current environment. */
export const ENVIRONMENT_SLUG =
	import.meta.env.VITE_ENVIRONMENT_SLUG && typeof import.meta.env.VITE_ENVIRONMENT_SLUG === 'string'
		? import.meta.env.VITE_ENVIRONMENT_SLUG
		: null;

// The current frontend Base URL
export const BASE_URL = (import.meta.env.VITE_BASE_URL as string) || null;
export const IS_MAIN_DOMAIN =
	BASE_URL && new RegExp(/^https?:\/\/square-broking\.com\/*$/).test(BASE_URL);

export const DASHBOARD_BASE_URL =
	import.meta.env.VITE_DASHBOARD_BASE_URL &&
	typeof import.meta.env.VITE_DASHBOARD_BASE_URL === 'string'
		? import.meta.env.VITE_DASHBOARD_BASE_URL
		: 'https://pre-production.dashboard.square-broking.com';
